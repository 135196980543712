body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    min-width: 99%;
    max-width: 99vw;
    background-color: #ffffff;
    min-height: 99vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: #000000;
    overflow: hidden;
}

.event-title {
    text-transform: uppercase;
    font-size: calc(25px + 7vmin);
}

.event-image {
    height: 40vmin;
}

.try-again {
    text-decoration: underline;
    cursor: pointer;
}

